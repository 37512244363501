// ----------------------------------------------------
//Variables
// ----------------------------------------------------

$violate: #30327d;
$lowvi: #202140;
$btnblue: #288dcc;
$greyish: #f5f6f8;

// ----------------------------------------------------
// breakpoints as variables
// ----------------------------------------------------
$tablet: 768px;
$desktop: 1024px;

// ----------------------------------------------------
// text colors
// ----------------------------------------------------

.text-purple-dark {
  color: $lowvi;
  fill: $lowvi;
}

.text-purple-midi {
  color: $violate;
  fill: $violate !important;
}

.text-blue-light {
  color: $btnblue;
  fill: $btnblue;
}

// ----------------------------------------------------
// background colors
// ----------------------------------------------------

.bg-purple-dark {
  background-color: $lowvi;
}

.bg-purple-midi {
  background-color: $violate;
}

.bg-blue-light {
  background-color: $btnblue !important;
}

.bg-main {
  background-color: $greyish;
  // min-height: 90vh;
  padding-inline: 1.5rem;

  @media only screen and (max-width: #{$tablet - 1}) {
    padding: 0 !important;
  }
}

// ----------------------------------------------------
// fonts
// ----------------------------------------------------

.ff-priamry {
  font-weight: 400;
  font-style: normal;
}

.c-fw-bold {
  font-weight: bold;
}

// ----------------------------------------------------
// global components
// ----------------------------------------------------

.slide-header {
  color: $violate;
  font-weight: 500;
}

.inline {
  vertical-align: middle;
  overflow-x: auto;
}

.inline-item {
  display: inline-block !important;
  vertical-align: middle;
  height: fit-content;
  margin-right: -4px;
}

.app-btn {
  text-transform: none !important;
  border-radius: 7px !important;
  padding: 8px 20px;
}

.yes-btn {
  font-size: 20px !important;
  padding: 10px 45px !important;
}

.standard-btn {
  padding: 8px 25px !important;
}

.custom-search {
  border-radius: 7px;
  border: solid 1px #ced4da;
  background-color: #fff;
}

// ----------------------------------------------------
// singular comps based on slides :
// ----------------------------------------------------

// service slide
// -------------

.stepper-layout {
  @media only screen and (max-width: #{$tablet - 1}) {
    padding-inline: 0 !important;
  }
}

.stepper-container {
  min-height: 10px;
  // padding: 7px 50px 7px 8px;
  padding: 7px 7px 7px 8px;
  // width: 100%;
  width: fit-content;
  border-radius: 3.5px;
  background-color: #e9ecef;
  // height: 100px;
  white-space: nowrap;
}

.step-complete-container {
  // width: fit-content;
  height: 65px;
  margin: 0 9px 0 0;
  // padding: 9px 24.1px 11px 23px;
  padding-inline: 0.8rem;
  padding-block: 0.5rem;
  border-radius: 7px;
  background-color: #007bff;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: none;

  .content {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 0.9rem;
      opacity: 0.67;
      white-space: nowrap;
    }
    .sub {
      font-weight: bold;
    }
  }
}

// appointment slide
// -----------------

.datelist-layout {
  @media only screen and (max-width: #{$tablet - 1}) {
    padding-inline: 0 !important;
  }
}

.date-list-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  // gap: 0.5rem;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  padding-bottom: 0 !important;

  .date-list-item {
    flex: 0 0 auto;
    width: fit-content;
    cursor: pointer;
    text-align: center;
    height: 100px;
    padding-inline: 2rem;

    @media only screen and (max-width: #{$tablet - 1}) {
      padding-inline: 1rem !important;
      height: 70px;
    }

    .primary-text-ltr {
      font-weight: bold;
      font-size: 1.1rem;
      color: $violate;
    }

    .secondary-text-ltr {
      color: #717290;
    }

    .primary-text-rtl {
      font-weight: bold;
      font-size: 1.3rem;
      color: $violate;
    }
    .secondary-text-rtl {
      font-size: 1.1rem;
      color: #717290;
    }
  }

  .selected {
    border-top-left-radius: 44px;
    border-top-right-radius: 44px;
    background-color: rgba(0, 123, 255, 0.21);
    position: relative;

    @media only screen and (max-width: #{$tablet - 1}) {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  .selected::after {
    content: "";
    width: 100%;
    border: 2px solid #007bff;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.date-list-container::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

.timeline-con {
  position: relative;
}

.chev-prev,
.chev-next {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.no-option {
  color: #bec0cd !important;
}

.chev-prev {
  left: 0;

  @media only screen and (max-width: #{$tablet - 1}) {
    left: 2%;
  }
}

.chev-next {
  right: 0;

  @media only screen and (max-width: #{$tablet - 1}) {
    right: 2%;
  }
}

.time-list {
  .item {
    padding-block: 0.8rem;
    border-radius: 7px;
    border: solid 1px #ced4da;
    background-color: #fff;
    margin-block: 0.8rem;
    cursor: pointer;
  }

  .full {
    border: solid 1px #e59d9d;
    background-color: rgba(229, 157, 157, 0.07);
    color: #b24242;
    cursor: not-allowed;
  }
}

.date-picker-container {
  position: relative;

  .date-picker-icon {
    position: absolute;

    top: 50%;
    transform: translate(-90%, -50%);
  }

  .ltr {
    right: 0%;
  }

  .rtl {
    left: 0%;
    transform: translate(90%, -50%);
  }
}

// request slide
// -----------------

.custom-textarea {
  resize: none !important;
  border-radius: 7px;
  border: solid 1px rgba(48, 50, 125, 0.17);
  background-color: #fff;
  padding: 1rem;
}

.options-contianer {
  padding-left: 1.5rem;

  @media only screen and (max-width: #{$tablet - 1}) {
    padding-left: 0;
  }
}

.textarea-container {
  position: relative;

  .app-btn {
    position: absolute;
    bottom: 10%;

    padding: 10px 25px;
    font-size: 16px !important;
  }

  .ltr {
    right: 2%;
  }

  .rtl {
    left: 2%;
  }
}

// -----------------
// waiting list
// -----------------

.option-header {
  font-weight: bold;
}

// -----------------
// other
// -----------------

// text field (material ui)
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  &::placeholder {
    height: 100px;
  }
}

// li list (material ui)
.css-tlelie-MuiListItemText-root {
  max-width: 80%;
}

// triangle (react-datepicker)
.react-datepicker__triangle {
  left: 50% !important;
}

// ------------------------
// larger screens
// ------------------------
.option-sm,
.sub-btn-sm {
  display: none !important;
}

// ------------------------
// mobile version
// ------------------------

@media only screen and (max-width: #{$tablet - 1}) {
  .option-lg,
  .sub-btn-lg {
    display: none !important;
  }

  .option-sm,
  .sub-btn-sm {
    display: block !important;
  }

  .sub-btn-sm {
    width: 100%;
    padding-block: 0.5rem !important;
    font-size: 1.3rem !important;
    margin-top: 1rem !important;
  }
}


// ------------------------
// patient profile
// ------------------------

.text-clr-primary{
  color:#31327d,
}
.bg-clr-primary{
  background-color:#31327d,
}

.text-clr-secondary{
  color:#e9ecef,
}
.bg-clr-secondary{
  background-color:#e9ecef,
}

.form-container{
  padding-bottom: 2rem;
  padding-top: 0.5rem;
  padding-inline: 2rem;
  border-radius: 41px;
  background-color: #e9ecef ;
}
.form-radius-1{
  border-radius: 41px;
}

.form-padding-inline{
  padding-inline: 2.5rem;
  
 
}
.field-border{
  position: relative;
  
  
}
.field-border::after{
  content: '';
  position: absolute;
  left: 0.8rem;
  bottom: 0;
  height: 1px;
  width: 90%;
  background-color: #30327d;
  opacity: 0.19;
}

.field-border.hrRtl::after {
  right: 0.8rem;
  left: auto; /* This disables the left positioning for RTL */
}

.custom-hr{
  background-color: #f5f6f8;
  height: 2px;
  border-color: transparent !important;
}





.cfw-bold{
  font-weight: bold;
}

.no-caps{
  text-transform: none !important ;
}

.civil-mock{
  // height: 220px;
  // @media (min-width: 768px) and (max-width:  892px) {
  //   height: 200px;
  // }

  // @media (max-width: 767.98px) {
  //   height: 200px;
  // }

  @media  (min-width: 768px) and (max-width:  1324px) {
    height: 250px;
  }

}


.custom-select {
  // padding-right: 2em; 
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 21 21"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m14.5 8.5l-4 4l-4-4"/></svg>') no-repeat;
  background-position: right 0.5em center;
  background-size: 1em;
  appearance: none; 
  background-color: white;
}

.selectRtl{
  // padding-right: 0 !important;
  // padding-left: 2em !important;
  padding-right: 1rem !important;
  background-position: left 0.5em center;
}

/* To remove the default dropdown arrow in IE */
.custom-select::-ms-expand {
  display: none;
}

.fs-rtl{
  font-size: 1.2em;
}
.btn-fs-rtl{
  font-size: 1.2em !important;
}