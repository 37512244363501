* {
  outline: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* line-height: 1.3; */
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
/* ... other styles ... */

.custom-toggle {
  width: 44px;
  height: 24px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s;
  border: 2px solid gray;
}

.custom-toggle input {
  display: none;
}

.custom-toggle-slider {
  position: absolute;
  top: 50%; /* position at center vertically */
  left: 4px;
  right: unset; /* adjusted position */
  width: 15px;
  height: 15px;
  background-color: black;

  border-radius: 50%;
  transition: transform 0.3s;
  transform: translateY(-50%); /* pull up by half its height to center */
}

.custom-toggle input:checked + .custom-toggle-slider {
  transform: translate(
    19px,
    -50%
  ); /* maintain vertical centering while moving horizontally */
}
