//Variables
$primary: #288dcc;
$white: #ffff;
$aqua: #31327d;
$aquatext: #ffff;
$grey: #c3c3c3;
$disabledGrey: #e4e4e4;
$danger: #dc3546;
$blackColor: #212529;
$lightGrey: #f5f6f8;
$blue: blue;
$lowGrey: rgba(0, 0, 0, 0.05);

/* Font related variables */
$font-weight-medium: 500;
$font-weight-large: 700;
$print-font-size: 1rem;
$print-font-large-size: 1rem;
$print-header-size: 1.2rem;
// ====================================MIXIN=================================

// Media queries
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xlg: 1500px,
);

//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
.pl-0 {
  padding: 0 10px;
}

.mb-0 {
  text-align: left;
}

//styles for arabic view
body:lang(ar) {
  direction: rtl;
  text-align: right;
  .language_change {
    right: unset !important;
    left: 20% !important;
  }
  .fc-toolbar-title {
    direction: rtl !important;
  }
  .ml-auto {
    margin-left: unset !important;
    margin-right: auto !important;
  }
  #fc-dom-1 {
    direction: rtl !important;
  }
  .navigation-btn {
    text-align: right !important;
  }
  .mb-0 {
    text-align: right;
  }

  .fa-long-arrow-right {
    transform: rotateY(180deg);
    &.ml-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important;
    }
  }

  .pl-0 {
    padding: 30px;
  }

  .back i {
    padding-left: 10px;
  }

  .mr-4 {
    margin: 0 20px;
  }

  .modal-header {
    position: relative;
    .close {
      position: relative !important;
      padding: 0 1px;
      margin: 1px;
    }
  }
}

html {
  overflow-y: auto !important;
}
.h100vh {
  height: 100vh;
  @include respond-below(sm) {
    height: 100%;
  }
}

body {
  color: $blackColor;
  // enable background Chrome, Safari
  -webkit-print-color-adjust: exact !important;
  // Firefox
  color-adjust: exact !important;
}
.w100 {
  width: 100%;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.app_container {
  min-height: 100vh;
}
// * {
//   line-height: 1.5;
// }
.table td,
.table th {
  padding: 0.5rem;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0 !important;
  background-color: #fff !important;
  color: #000 !important;
  cursor: pointer !important;
}
.react-datepicker__day--keyboard-selected:hover {
  border-radius: 0.3rem !important;
  background-color: #f0f0f0 !important;
}

.text-red {
  color: $danger !important;
}

.pass-text-white {
  color: white !important;
  font-size: 12px !important;
  margin-top: 10px !important;
}
.pass-text-grey {
  color: grey !important;
  font-size: 12px !important;
  margin-top: 10px !important;
}
.passReq {
  &ol {
    li {
      margin-left: 25px !important;
      list-style-type: disc !important;
    }
  }
}

.navigation_conainer {
  max-height: 100vh;
  overflow-y: auto;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: $primary;
  align-items: center;
  flex: 1;
  // @include respond-between(sm, md) {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
  .flex4 {
    flex: 0.2;
    width: 100%;
    height: 100%;
    @include respond-below(sm) {
      display: none;
    }
  }
  .flex6 {
    flex: 0.8;
    width: 100%;
    height: 100%;
    @include respond-below(sm) {
      display: none;
    }
  }

  .menu_icon {
    font-size: 1.5rem;
    color: $white;
  }
  .user_details {
    text-align: center;
  }
  .navLogo {
    width: 150px;
    // border-radius: 50%;
    object-fit: contain;
    max-height: 150px;
    @include respond-below(sm) {
      width: 190px;
      height: 134.33px;
      object-fit: contain;
    }
  }

  .flex1 {
    flex: 1;
  }
  .today_date {
    color: $white;
    font-size: 0.75rem;
  }
  .mobile_nav {
    .nav-date {
      text-align: right;
    }
    display: none;
    @include respond-below(md) {
      display: flex !important ;
      height: 60px;
      justify-content: space-between;
      align-items: center;
    }
  }
  @include respond-below(md) {
    height: 60px;
  }
}
.login_page {
  height: 100vh;
  background-color: $aqua;
  &.varifyPage {
    background-color: $white;
  }
}

.error-title {
  font-size: 3rem !important;
  text-align: center !important;
  font-weight: bold !important;
}

.error-text {
  font-size: 1.5rem !important;
  text-align: center !important;
}

.error-container {
  height: 95vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  a {
    color: white !important;
  }
}

.login_page__logo {
  align-self: center;
  justify-content: center;
  display: flex;
  margin-bottom: 100px;
  @include respond-below(sm) {
    display: none;
  }
}
input[data-readonly] {
  pointer-events: none;
}
.login_page__form {
  background-color: $aqua;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.language_change {
  @media (min-width: 1400px) {
    padding-right: 60px;
    padding-left: 40px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    padding-right: 40px;
    padding-left: 20px;
  }
}

.logotype {
  .navLogo {
    height: 300px !important;
    width: 300px !important;
  }
  &.mobileLogo {
    display: none;
    @include respond-below(sm) {
      max-width: 100%;
      display: flex !important;
      // padding-top: 2rem;
      // padding-bottom: 2rem;
      justify-content: center;
      img {
        border-radius: 5%;
        max-width: 75vw;
        margin-bottom: 0;
      }
    }
  }
  @include respond-below(sm) {
    &.navLogoColor {
      max-width: 50vw;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 100px;
    padding-left: 25px;
    padding-right: 30px;
  }
  @media (min-width: 1024px) and (max-width: 1199px) {
    padding-top: 80px;
    padding-left: 40px;
    padding-right: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    padding-top: 90px;
    padding-left: 100px;
    padding-right: 100px;
  }
  @media (min-width: 1400px) {
    padding-top: 60px;
    padding-left: 110px;
    padding-right: 120px;
  }
}

// .search {
//   position: absolute;
//   top: 50%;
//   z-index: 2;
//   transform: translate(0, -50%);
//   right: 15px;
//   height: 96%;
//   width: 41px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 0 5px 5px 0;
//   display: flex;
//   justify-content: flex-end;
//   i {
//     color: $grey;
//     padding-right: 15px;
//   }
// }

.button-below-wdth9 {
  position: absolute;
  bottom: 4%;
  width: 90%;
  @include respond-below(sm) {
    width: 100%;
  }
}
.button-below {
  position: absolute;
  bottom: 4%;
}
.font-size {
  font-size: 1.5rem;
}

.search-icon {
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translate(0, -50%);
  right: 15px;
  left: 15px;
  cursor: pointer;
  height: 96%;
  min-width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: flex-end;
  &:hover {
    transition: 300ms;
    background-color: rgba(0, 123, 255, 0.25);
  }
  i {
    color: $primary;
    padding-right: 15px;
  }
}

.search-icon-disabled {
  pointer-events: none;
}

.tabs {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  .logout-btn {
    margin-bottom: 20px !important;
  }

  .btn-link {
    margin-bottom: 0px;
    color: $white;
    cursor: pointer;
    display: block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1.15rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    &:hover,
    &:focus {
      text-decoration: none;
    }
    i {
      color: $white;
      margin-right: 0.5rem;
    }
  }
}

.user_details {
  text-align: center;
  display: flex;
  justify-content: "center";
  align-items: center;
  .navLogo {
    // max-width: 190px;
    width: 190px;
    height: 134.33px;
    object-fit: contain;
    padding-top: 20px !important;
  }
  .today_date {
    color: $white;
    font-size: 0.75rem;
    opacity: 0.5;
  }
}
.lang-patient-change {
  color: $white;
  font-size: 0.75rem;
  opacity: 1;
  border-radius: 4px;
  border: 1px white solid;
  padding: 4px;
  text-align: center;
}
.d-flex-column-str {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.px-5rem {
  padding-left: 3rem;
  padding-right: 3rem;
  @include respond-below(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.back-button-color {
  color: $aqua !important;
}

.page_header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  // max-width: 1140px;
  margin-top: 40px;
  i {
    color: $primary;
    margin-right: 0.5rem;
  }
}
.filter-block {
  &__patient-list {
    position: absolute;
    z-index: 2;
  }
  ul {
    max-height: 43vh;
    overflow: auto;
    margin-top: -10px;
    border-radius: 0;
  }
}

.btn-link {
  color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

a {
  color: black;
}
a:hover {
  text-decoration: none;
}
.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  &:hover,
  &:focus {
    border-color: $primary !important;
  }
}

.borderRadius {
  border-radius: 0.25rem !important;
}
.btn-outline-primary {
  color: $primary !important;
  border-color: $primary !important;
  &:hover,
  &:focus {
    border-color: $primary !important;
    color: $white !important;
    background-color: $primary !important;
  }
}
.buttons_container {
  .btn-primary {
    font-size: 1.3rem;
  }
}

.upload-icon {
  font-size: 3rem;
}

.btn-pg-low {
  padding: 0.17rem 0.4rem !important;
}

.list-group-item {
  padding: 0.24rem 0.25rem !important;
}
.textGrey {
  color: grey;
}
.textPrimary {
  color: $primary;
}
.textPrimaryBold {
  font-weight: 700;
  color: $primary;
}
.textGreyBold {
  font-weight: 700;
  color: grey;
}
.card {
  box-shadow: 0 3px 15px -2px #dfdfdf;
  margin-bottom: 10px;
  border-radius: 10px;
}
.card-body {
  padding: 0.85rem;
}
.fc-toolbar-title {
  font-size: 1.5rem !important;
}
.waitingHighlights {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  @include respond-below(sm) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.payment-section {
  label {
    padding-right: 15px !important;
  }
  &__patient-list {
    position: absolute;
    z-index: 2;
    ul {
      max-height: 43vh;
      overflow: auto;
      margin-top: -10px;
      border-radius: 0;
    }
  }
  .card {
    border: 1px solid rgb(126, 126, 126);
  }
}
.down-payment {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  input {
    text-align: center;
    width: 80px;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
}
.settings_section {
  .fullPayment {
    margin-bottom: 1rem;
    .input-group {
      width: 80px;
      margin-left: 0.8rem;
    }
  }
}
.search_patient {
  .search_result {
    height: 40vh;
    span {
      display: block;
    }
  }
  .btn-primary {
    font-size: 0.875rem !important;
    padding: 0.75rem 2rem;
  }
  a,
  input {
    font-size: 14px;
  }
}
.font-size-sm {
  font-size: 0.875rem;
}
.react-datepicker__header,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $primary;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: $primary !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__month-read-view--selected-month,
.react-datepicker__year-read-view--selected-year,
.react-datepicker__day-name {
  color: #fff !important;
}
.react-datepicker__week-number {
  color: #288dcc !important;
}
.react-datepicker-popper {
  z-index: 3;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: $primary;
}
.react-datepicker {
  display: flex;
}
.create-event-datepicker {
  .react-datepicker {
    flex-direction: column;
  }
}
.react-datepicker__navigation,
.react-datepicker__navigation--next {
  padding: 0 !important;
}
.react-datepicker__navigation,
.react-datepicker__navigation--next:focus {
  outline: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.pointer {
  cursor: pointer !important;
}
.right_container {
  max-height: 100vh;
  overflow-y: auto;
  height: auto;
  @include respond-below(lg) {
    // max-height: 100% !important;
    padding-bottom: 100px;
    height: 98vh !important;
  }
}

.name-link {
  display: flex;
  align-items: center;
  button {
    padding: 0;
  }
}

.require-payment-checkbox {
  padding: 0 !important;
  input {
    padding: 0 !important;
    // margin: 0 !important;
    margin-left: 10px !important;
    margin-top: 7px !important;
  }
}

.helpers-check-icon {
  color: green;
}

.helpers-fail-icon {
  color: $danger;
}

.payment-link-icon {
  color: $primary;
}

.wating-list-icon {
  font-size: 1.3rem;
}

.payment-table-cell {
  button {
    border: none;
    display: inline-block;
    padding: 10px;
  }
}

div[class^="Snackbar_snackbar-wrapper"] {
  z-index: 10000;
}

@media screen and (min-width: 1140px) {
  .float-container {
    float: left;
  }
  .float-container-right {
    float: right;
  }
}

.btn {
  border-radius: 0.25rem;
  @media (min-width: 320px) and (max-width: 480px) {
    border-radius: 0.25rem;
    font-size: 12px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    border-radius: 0.25rem;
    font-size: 15px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    border-radius: 0.25rem;
    font-size: 13px;
  }
}
.btn-lg {
  font-size: 1rem;
  margin-bottom: 15px;
  min-width: 240px;
}
.fc .fc-non-business,
.fc-day-disabled {
  background: $disabledGrey !important;
}
.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 !important;
}
.fc-v-event {
  // background: $white !important;
  // border: 1px solid $white !important;
}

.colorlowGrey {
  color: $lowGrey !important;
}
.colorBlue {
  color: $blue !important;
}
#searchResults {
  th,
  td {
    padding: 0.2rem;
  }
  thead {
    background-color: $lowGrey;
  }
  tbody tr {
    background-color: $white !important;
  }
  .table_footer {
    background-color: $lowGrey !important;
  }
}
.diagnoses_table {
  height: 200px;
}
.patient_table_row {
  th,
  td {
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
  }
  &.tableHeader {
    th {
      background-color: $primary;
      color: $white;
    }
  }

  td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $grey;
  }
}
.spinner-block {
  &__elem {
    background-color: $primary;
  }
  min-height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100vh;
}
.positionAbsoluteLoader {
  position: absolute;
  width: 100%;
  z-index: 555;
  background: white;
  opacity: 0.75;
  // margin-top:200px;
  height: 90%;
  bottom: 0;
  // @include respond-below(sm) {
  //   margin-top:180px;
  // }
}
.btn:focus {
  box-shadow: none !important;
}
.login_form__button {
  min-width: 160px;
  margin-top: 40px;
  background: $primary !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}

.colorAqua {
  color: $aquatext !important;
}

.btn-sm {
  padding: 0.5rem 1.3rem;
  font-size: 0.875rem;
  // width: 85px;
  // font-weight: bold;
  // text-transform: uppercase;
}
.btn-md,
.dropdown > button {
  padding: 0.5rem 1.3rem;
  font-size: 1rem;
  // font-weight: bold;
  // text-transform: uppercase;
}
.patient_page_buttons {
  .dropdown > button {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
// .btn {
//   font-weight: bold;
//   text-transform: uppercase;
// }
.btn-primary,
.btn-secondary,
.btn-danger {
  // -webkit-box-shadow: 0px 0px 2px 1px rgba(32, 63, 135, 0.6);
  // -moz-box-shadow: 0px 0px 2px 1px rgba(32, 63, 135, 0.6);
  // box-shadow: 0px 0px 2px 1px rgba(32, 63, 135, 0.6);
  font-weight: bold;
  text-transform: uppercase;
}
.active {
  transform: scale(1.01);
  color: $primary;
  backface-visibility: inherit;
}
.patient__block {
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 55vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1.5rem;
  ul {
    overflow: auto;
  }
  ul li {
    font-size: 15px;
    cursor: pointer;
    margin: 10px 5px;
    transition: 100ms;
    display: block;
    &:hover {
      transform: scale(1.01);
      color: $primary;
      backface-visibility: inherit;
    }
  }
}
.doctor_session {
  display: block;
  color: $primary;
  display: flex;
}
.online-link-error {
  display: block;
  color: $danger;
  text-align: left;
}
.form__action {
  font-style: italic;
  color: $primary;
  cursor: pointer;
}
.patient_app_container {
  max-height: 70vh;
  overflow: auto;
}
.thinScrollBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #cfd1ce;
  background-color: #cfd1ce;
}

.thinScrollBar::-webkit-scrollbar {
  width: 0.1875rem !important;
  background-color: #cfd1ce;
}

.thinScrollBar::-webkit-scrollbar-thumb {
  background-color: $primary;
  border: 3px solid $primary;
}
.fc-event-main {
  overflow: hidden;
}
.selectedSlot {
  .fc-event-main {
    background: $primary !important;
    border-color: $primary !important;
  }
  div {
    // $white and $aquatext is the same #ffff
    color: $aquatext !important;
  }
}

iframe.diagnosis-iframe {
  width: 100%;
}

.form__search_list {
  position: absolute !important;
  z-index: 2;
  ul {
    max-height: 43vh !important;
    overflow: auto !important;
    margin-top: -16px !important;
    border-radius: 0;
    li {
      line-height: 1;
      cursor: pointer;
    }
  }
}
.forgot_password {
  color: $white;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  &:hover {
    color: $white;
  }
}

.vitals-table {
  table-layout: fixed;
}

.fc-timegrid-slot-lane {
  background: rgb(238, 238, 238);
  pointer-events: none !important;
}
.dark_link {
  &:hover {
    color: black;
  }
}
.grey-text {
  color: grey !important;
}

.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #ced4da !important;
}

.grey-text {
  color: grey !important;
}
.page-item.active .page-link {
  background: $primary;
  border-color: $primary;
}
.page-link {
  color: $primary;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  &:hover {
    color: $primary;
  }
  &:focus {
    box-shadow: none;
  }
}
.page-item {
  width: 35px !important;
}

.h100 {
  height: 100%;
}

.col-pad {
  @media (min-width: 1024px) and (max-width: 1400px) {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}

.button_block {
  // position: absolute;
  width: 100%;
  margin-top: 6rem;
  // bottom:-50%;
  justify-content: center;
  button {
    min-height: 38px;
    height: auto;
    min-width: 220px;
  }
}
.capitalize {
  text-transform: capitalize;
}

.w100 {
  width: 100%;
}

.modal60h {
  max-width: 60vw !important;
  @include respond-below(md) {
    max-width: 85vw !important;
  }
  @include respond-below(sm) {
    max-width: 85vw !important;
  }
  .modal-body {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.modals {
  padding: 1rem 1.5rem !important;
  margin: 0 auto !important;
  width: 60vw !important;
  border-radius: 30px !important;
  max-width: unset !important;
  @include respond-below(sm) {
    padding: 0rem !important;
    margin: 0 !important;
    width: 85vw !important;
  }
  button {
    padding: 0.2rem 0.6rem;
  }
}

.modals-from-formBlock {
  @include respond-below(sm) {
    width: 95vw !important;
    // height: 100vh !important;
  }
  @include respond-between(sm, md) {
    width: 90vw !important;
    // height: 90vh !important;
  }
  @include respond-between(md, lg) {
    width: 90vw !important;
    // height: 90vh !important;
  }
  max-width: unset !important;
}

.waiting_table {
  th {
    font-weight: normal;
  }
}
.react-datepicker-wrapper {
  width: 100%;
}
.submitPayment {
  // margin-right:6rem;
  padding-bottom: 2rem;
}

/* Content classes and styles */
.section-header {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: $font-weight-large;
}

.grid-title {
  text-transform: uppercase;
  font-weight: $font-weight-large;
  color: $primary;
}

.grid-sub-title {
  font-size: 1.2rem;
}

.icon-image-container {
  font-size: 2.8rem;
  height: 4rem;
  width: 4rem;
}

.centered-figure {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}

.figure-caption {
  color: $blackColor;
}

.table-header {
  color: $white;
  background-color: $primary;
  text-transform: uppercase;
}

/* End content styles */

/* Common classes */

.p-lg-10 {
  padding: 5rem !important;
}
.dashboard_block {
  height: 200px;
  padding-left: 100px;
  .title {
    color: $primary;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
  }
}
.base_title {
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .base_title {
    padding-bottom: 10px;
    font-size: 16px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .base_title {
    padding-bottom: 30px;
    font-size: 16px;
  }
}
@media (min-width: 769px) and (max-width: 939px) {
  .base_title {
    font-size: 18px;
  }
}

.backBtn {
  color: #288dcc;
  font-size: 16px;
}

.form__button {
  min-width: 80px;
}
.w100 {
  width: 100%;
}
.modal-60w {
  @include respond-below(xs) {
    margin: 0 auto;
    width: 100vw !important;
  }
  width: 60vw !important;
  max-width: unset !important;
  .modal-content {
    border-radius: 30px !important;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
.submitPayment {
  padding-bottom: 2rem;
}
.nav-tabs .nav-item {
  @include respond-below(xs) {
    margin-bottom: 0px;
    flex-grow: 1;
    white-space: nowrap;
  }
}
.nav-link {
  @include respond-below(xs) {
    padding: 0;
  }
}
.nav-tabs {
  @include respond-below(xs) {
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: 0 2%;
  }
}

.container {
  @include respond-below(md) {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.text-right {
  @include respond-below(sm) {
    text-align: left !important;
  }
}
.text-xs-right {
  text-align: right !important;
}

.pst-absolute-130px {
  @include respond-below(sm) {
    position: absolute;
    left: 130px;
  }
}
.pst-absolute-160px {
  @include respond-below(sm) {
    position: absolute;
    left: 212px;
  }
  @include respond-below(xs) {
    position: absolute;
    left: 160px;
  }
}
.printModalScroll {
  max-height: 75vh;
  overflow-y: auto;
}
.pst-absolute-125px {
  @include respond-below(xs) {
    position: absolute;
    left: 125px;
  }
}
.center {
  @include respond-below(sm) {
    margin: 0 auto;
  }
}

.fc-toolbar-title {
  @include respond-below(md) {
    font-size: 1.2rem !important;
  }
  @include respond-below(xs) {
    text-align: center !important;
    font-size: 1rem !important;
  }
}
.fc-daygrid-event-dot {
  @include respond-below(xs) {
    margin: 0px 1px !important;
  }
}
.fc-col-header-cell-cushion {
  @include respond-below(xs) {
    padding: 0 !important;
  }
}

.fc-button {
  @include respond-below(xs) {
    padding: 0.7em 0.7em !important;
    font-size: 0.5em !important;
    margin-top: 0.5em !important;
  }
}

.fc-daygrid-event-harness {
  overflow: hidden;
}
.fc-daygrid-day-bottom {
  @include respond-below(xs) {
    text-align: center !important;
  }
}

.fc-event-future,
.fc-event-end {
  @include respond-below(xs) {
    font-size: 0.7rem !important;
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  background-color: $primary;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-shadow: 9px -2px 5px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 9px -2px 5px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 9px -2px 5px -11px rgba(0, 0, 0, 0.75);
  .btn {
    font-size: 1.3rem;
    white-space: nowrap;
  }
  .btn-link,
  h6,
  h4 {
    color: $white;
  }
  a {
    text-decoration: none;
    font-size: 25px;
    color: $primary;
    display: block;
    transition: 0.3s;
    &:hover {
      color: $primary;
    }
  }
  .closebtn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.2rem;
    i {
      color: $white;
    }
  }
}
#nav_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.userdetails {
  white-space: nowrap;
}
.baseContainer {
  @include respond-below(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}
.back {
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: #fff;
  }
}
.text-primary-color {
  color: $primary;
}

.patients_filter {
  i {
    font-size: 2rem;
  }
}
#dropdown-basic-button {
  color: $blackColor;
}
.paymentListCard {
  border: none !important;
  box-shadow: none !important;
}
.dummy_patient_details {
  margin-left: -100px;
}
.patient_nav {
  background-color: $aqua !important;
  z-index: 999;
}
.patient_dashboard {
  .card {
    margin-bottom: 2rem;
    border-radius: 25px;
    border: none;
    .card-body {
      color: $white;
      padding: 1.5rem;
      &.ht200 {
        height: 200px;
        @media screen and (max-width: 1500px) {
          height: 270px;
        }
        @include respond-below(lg) {
          height: 100% !important;
        }
      }
      .icon_large {
        font-size: 4rem;
      }
      p {
        font-size: 0.9rem;
      }
      button {
        font-size: 0.75rem;
        color: $white;
        i {
          font-size: 1.3rem;
        }
      }
      .text_container {
        width: 100%;
        // height:135px;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        @include respond-below(lg) {
          height: 100% !important;
        }
      }
    }
  }
  .orange1 {
    background-color: #f0714e;
  }
  .orange2 {
    background-color: #d84e41;
  }
  .green {
    background-color: #40c3a5;
  }
  .blue {
    background-color: #4383fb;
  }
  .honey {
    background-color: #fab74c;
  }
}
.greyBg {
  background-color: $lightGrey;
}
.whiteBg {
  background-color: $white;
}
.font-bold {
  font-weight: bold;
}
.table-responsive {
  min-height: 300px;
}
.dummy_patient_details {
  margin-left: -100px;
}

.icon {
  text-align: center;
  button {
    display: inline-block;
  }
  svg {
    color: #288dcc !important;
    font-size: 1.9rem !important;
  }
}

.no-col-padding {
  .col-md-8,
  .col-sm-8 .col-12 {
    padding: 0 !important;
  }
}

.notes-textarea-container {
  padding: 0 !important;
}

.notes_container {
  .form-group {
    margin-bottom: 0;
  }
}

.encounter-container {
  padding: 0;
}

.hide-input {
  position: absolute;
  opacity: 0;
  left: 20px !important;
  z-index: -1 !important;
}

@include respond-below(xs) {
  .modal-dialog-centered {
    min-height: 100vh !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

@include respond-between(xs, sm) {
  .modal-dialog-centered {
    min-height: calc(100vh - 100px) !important;
  }
}

// ! patient black and white card
// @media screen and (max-width: 767px) {
@include respond-below(sm) {
  .navigation-btn,
  .logout-btn {
    font-size: 1rem !important;
  }

  .patient-info-appointment {
    margin-top: 1rem;
  }

  .patient_dashboard {
    margin-top: 20px;
  }

  .notes-modal {
    width: 100% !important;
    min-height: calc(100vh - 50px);
  }

  // .encounter-container {
  //   padding: 0;
  // }

  .patient-special-data-card-inner-content {
    height: 100% !important;
    padding-bottom: 15px;
  }

  .login-header {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .login_form__button {
    margin-top: 10px;
  }
}
.patient-personal-data-card {
  @include respond-below(lg) {
    .px-5 {
      padding: 20px !important;
    }
  }
}

.patient-capitalize-first-letter:first-child {
  text-transform: capitalize;
}

// uncomment min n max width
.patient-record-btn {
  padding: 10px;
  // min-width: 140px;
  // max-width: 202px;
  a {
    color: #fff;
  }
  .patient-record-link {
    color: #fff;
  }
}

.dragPic img {
  height: 100px;
  // padding-left: 80px;
}

.patient-record-link {
  width: 100%;
  color: #fff;
}

@media (min-width: 320px) and (max-width: 480px) {
  .patient_page_buttons .dropdown > button {
    font-size: 10px;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .patient_page_buttons .dropdown > button {
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .patient_page_buttons .dropdown > button {
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .patient_page_buttons .dropdown > button {
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1201px) {
  .patient_page_buttons .dropdown > button {
    font-size: 13px;
    display: flex;
    flex-direction: column;
  }
}

.patient_page_buttons .dropdown > button {
  width: 100% !important;
  justify-content: space-evenly !important;
}

@include respond-between(sm, md) {
  .encounter-button-container {
    margin-top: 60px;
  }
}

@include respond-below(xs) {
  .encounter-button-container {
    flex-direction: column;
    button {
      margin-bottom: 10px;
    }
  }
}

.patient-record-dropdown {
  width: 100%;
  button {
    height: 100%;
    width: 100%;
  }
}

.encounter-dropdown {
  button {
    font-size: 12px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .patient-record-btn-link,
  .patient-record-btn {
    padding: 10px 5px;
    font-size: 10px;
  }

  .btn-small-display-padding-encounter {
    padding: 10px 20px;
  }

  .btn-small-display-padding-intake {
    padding: 10px 30px;
  }

  .encounter-dropdown {
    button {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 575px) {
  .encounter-dropdown {
    button {
      padding: 6px 12px !important;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .patient-record-btn-link,
  .patient-record-btn {
    padding: 10px;
    font-size: 12px;
  }

  .btn-small-display-padding-encounter {
    padding: 10px 20px;
  }

  .btn-small-display-padding-intake {
    padding: 10px 30px;
  }

  .encounter-dropdown {
    button {
      font-size: 15px;
      padding: 18px 12px;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .patient-record-btn-link,
  .patient-record-btn {
    font-size: 12px;
  }

  .encounter-dropdown {
    button {
      font-size: 17px;
      padding: 19px 12px;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .patient-record-btn-link,
  .patient-record-btn {
    font-size: 14px;
  }

  .encounter-dropdown {
    button {
      font-size: 16px;
      padding: 18px 12px;
    }
  }
}

@media screen and (min-width: 1201px) {
  .patient-record-btn-link,
  .patient-record-btn {
    font-size: 13px;
  }
  .encounter-dropdown {
    button {
      font-size: 16px;
      padding: 18px 12px;
    }
  }
}

@media screen and (max-width: 767px) {
  .patient-personal-data-card {
    margin: 0 15px;
    .patient-card-value {
      // vertical-align: middle;
    }
  }

  .patient-special-data-card {
    max-width: 375px;
    margin: 0 auto;
  }

  .btn-link-no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .i-no-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sidenav a:first-child {
    display: flex;
    justify-content: center;
    margin: 0px auto 20px auto;
  }

  .login-page-h3 {
    margin-bottom: 0 !important;
  }

  .login_page {
    padding-left: 20px;
  }

  .login-page-render-form {
    margin-top: 0 !important;
  }

  .login_page__form {
    justify-content: flex-start;
  }

  .page_header {
    height: auto;
    margin-top: 30px;
  }

  .searchForPatient-mobile {
    .mt-5 {
      margin-top: 0 !important;
    }
    .patient__block {
      height: 45vh;
    }
  }

  .button_block {
    width: auto;
  }

  .payment-section {
    label {
      padding-right: 15px !important;
    }
  }
}
.appointment-detail-page {
  // margin-top: 3rem;
  @include respond-below(lg) {
    margin-top: 5rem !important;
  }
  @include respond-below(sm) {
    margin-top: 2rem !important;
  }
}

.patient-card {
  height: 336px;
  .patient-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.patient-cards-container {
  @include respond-above(sm) {
    padding-left: 0;
    padding-right: 0;
    .patient-card-title {
      height: 38px;
    }
  }
}

// @media screen and (min-width: 768px) and (max-width: 991px) {
@include respond-between(sm, md) {
  .patient-card-outer-data {
    padding: 0;
  }
  .patient-card-value {
    // margin-left: 50px;
  }
  .personal-card-row-content {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    & div:nth-child(2) {
      padding-left: 0;
    }
  }

  .patient-personal-data-card {
    h6 {
      font-size: 0.9rem;
    }

    .card-left-text {
      text-align: left !important;
      span {
        font-size: 0.8rem;
        margin-right: 0 !important;
      }
    }
  }

  .patient-special-data-card {
    height: 350px !important;
    h6 {
      font-size: 0.9rem;
    }
    p {
      font-size: 0.8rem !important;
    }
  }

  .patient-special-data-card-inner-content {
    height: 90% !important;
    // padding-bottom: 30px;
  }

  // .ht200 {

  // }
}

// @media screen and (min-width: 992px) and (max-width: 1199px) {
@include respond-between(md, lg) {
  .patient-card-outer-data {
    padding: 0;
  }

  .patient-personal-data-card {
    h6 {
      font-size: 0.9rem;
    }

    .card-left-text {
      text-align: left !important;
      span {
        font-size: 0.8rem;
        margin-right: 0 !important;
      }
    }
  }

  .patient-special-data-card {
    height: 350px !important;
    padding-bottom: 30px !important;
    h6 {
      font-size: 0.9rem;
    }
    p {
      font-size: 0.8rem !important;
    }
  }

  .patient-special-data-card-inner-content {
    flex-direction: column;
    align-items: center;

    .icon_large {
      margin-bottom: 10px;
    }
  }

  .ht200 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .patient-special-data-card-inner-content {
    height: 100% !important;
    padding-bottom: 15px;
  }

  .user_details {
    text-align: center;
    .navLogo {
      width: 200px;
      height: 200px;
      object-fit: contain;
    }
  }
  .navigation_conainer {
    .navLogo {
      max-height: 100px;
      width: 200px;
      height: 200px;
      object-fit: contain;
    }
  }
}

.upload-files-notification {
  color: #797a7a;
}
.nav-email-overlay-mobile {
  text-overflow: ellipsis;
  width: 85%;
  overflow: hidden;
}
.nav-email-overlay-desktop {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
.disabled {
  pointer-events: none !important;
}
.opacity05 {
  opacity: 0.5;
}
@media screen and (min-width: 1500px) {
  .navigation_conainer {
    max-width: 285px;
  }
  .right_container {
    min-width: calc(100% - 285px);
  }
}

// @media screen and (min-width: 1200px) and (max-width: 1500px) {
@include respond-between(lg, xlg) {
  .navigation_conainer {
    max-width: 21%;
  }
  .right_container {
    min-width: 79%;
  }

  .patient-personal-data-card {
    h6 {
      font-size: 0.9rem;
    }

    .card-left-text {
      text-align: left !important;

      span {
        font-size: 0.8rem;
        margin-right: 0 !important;
      }
    }
  }

  .patient-special-data-card {
    max-height: 220px;
    h6 {
      font-size: 0.9rem;
    }
    p {
      font-size: 0.8rem !important;
    }
  }

  .patient-special-data-card-inner-content {
    height: 100%;
    padding-bottom: 15px;
  }
}

// @media screen and (min-width: 768px) {
@include respond-above(sm) {
  .tab-template-container {
    .tab-title {
      h3 {
        font-size: 1.3rem;
        cursor: pointer;
      }
    }
  }
}

// @media screen and (max-width: 991px) {
@include respond-below(md) {
  .patient-special-data-card-inner-content {
    flex-direction: column;
    align-items: center;

    .icon_large {
      margin-bottom: 10px;
    }
  }
}

// @media screen and (min-width: 1500px) {
@include respond-above(xlg) {
  .patient-card-outer-data {
    padding: 0;
  }

  .patient-special-data-card {
    max-height: 230px;
  }

  // arrows to the bottom
  .patient-special-data-card-inner-content {
    height: 100%;
    padding-bottom: 15px;
  }
}

.patient-personal-data-card {
  @include respond-below(lg) {
    .px-5 {
      padding: 20px !important;
    }
  }
}

.payment-container div:not(:last-child) {
  border-bottom: 1px solid #52525246;
}

.payment-container div:first-child {
  margin-top: 10px;
}

.payment-block {
  padding-bottom: 15px;
  margin-top: 20px;
  h6 {
    // margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.list-item-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 30px;
}

.upcoming-appointments-items {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.session-link {
  color: #288dcc;
  &:hover {
    color: $aqua;
  }
}

// @media screen and (max-width: 575px) {
@include respond-below(xs) {
  .appointment-detail-btn {
    margin-top: 20px;
  }

  .encounter-details-btn {
    margin-top: 20px;
  }

  .tab-template-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px !important;

    .tab-title {
      text-align: center;
      h3 {
        font-size: 1.3rem;
        cursor: pointer;
      }
    }
    .tab-content {
      margin-top: 20px;
      min-height: 150px;
    }
  }

  .notes-modal {
    width: 100% !important;
    min-height: calc(100vh - 15px);
  }

  .header-container-with-create-link {
    // background: red;
    display: flex !important;
    flex-direction: column-reverse !important;
    .order-first {
      a {
        margin: 0;
        button {
          margin-top: 20px;
        }
      }
    }
  }
}

// @media screen and (min-width: 575px) and (max-width: 767px) {
@include respond-between(xs, sm) {
  .tab-template-container {
    .tab-title {
      h3 {
        font-size: 1.3rem;
        cursor: pointer;
      }
    }
  }
}

table thead th {
  vertical-align: middle !important;
}
table tbody td {
  vertical-align: middle !important;
}

.modal-header {
  position: relative;
  .close {
    position: absolute;
    right: 10px;
  }
}
button:focus {
  outline: none !important;
}
.form_edit_buttons {
  .btn {
    &:not(.danger-color) {
      color: $blackColor !important;
    }
  }
}
input.search_field {
  background-image: none !important;
}
.search_field.no_match {
  background-color: rgba(var(--primary), 0.2) !important;
}

// .focus-in {
//   z-index: 100;
// }
.medicationBlock {
  height: 200px;
  @include respond-above(xlg) {
    height: 200px;
  }
  #searchResults {
    width: 60% !important;
  }
}
#searchResults {
  top: 0 !important;
  left: 0 !important;
  z-index: 1909 !important;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #cfd1ce;
    background-color: #cfd1ce;
  }

  &::-webkit-scrollbar {
    width: 0.1875rem !important;
    background-color: #cfd1ce;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aeb4be;
    border: 5px solid $primary;
  }
}
.bred {
  border: 2px solid red;
}
#reader_log {
  display: none !important;
}
.notes_area {
  width: 100%;
  padding: 15px;
  border: 2px solid #d0d5dc;
  max-height: 68vh;
  min-height: 38vh;
  overflow-y: auto;
  border-radius: 10px;
  .form-control:disabled {
    background: unset !important;
  }
}
.textBold {
  font-weight: bold;
}

.notes_block {
  position: relative;
  .notes_title {
    .title_edit {
      visibility: hidden;
    }
    &:hover {
      .title_edit {
        visibility: visible;
      }
    }
  }
  .edit_note {
    visibility: hidden;
    position: absolute;
    right: 0%;
    top: 45px;
  }
  &:hover {
    .edit_note {
      visibility: visible;
    }
  }
  .form-control {
    padding-right: 40px;
  }
}
.icon-black {
  i {
    color: $blackColor;
    &:hover,
    &:focus {
      color: $blackColor;
    }
  }
}
.expand-icon {
  display: inline-block;
  position: absolute;
  right: 20px;
  z-index: 1035;
}
.add_button {
  margin-right: 30px;
}
.modal-90w {
  width: 95vw !important;
  max-width: 95vw !important;
  height: 94vh !important;
  max-height: 94vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  z-index: 1040 !important;
  border-radius: 30px !important;
  background-color: $white !important;
  .modal-content {
    height: 100%;
    border: none !important;
    .modal-body {
      height: 100%;
      padding-bottom: 50px;
      .notes-textarea-container {
        height: 100%;
        textarea {
          height: 100%;
        }
      }
    }
  }
}
.opacityhalf {
  opacity: 0.75 !important;
}

.page_save_cancel_container {
  padding-bottom: 100px;
  .page_save_cancel {
    position: absolute;
    width: 100%;
    bottom: 20px;
  }
}
.singleBlockContainer {
  .singleBlockTitle {
    text-transform: uppercase;
  }
  .singleBlock {
    background-color: #eaecef;
    padding: 15px;
    width: 100%;
    border-radius: 15px;
    .encounter_block {
      height: 150px;
      overflow-y: auto;
    }
  }
}
.encounter_item {
  font-size: 14px;
  // display: inline-block;
  color: $primary;
}

.input-radio {
  display: inline-block;
  cursor: pointer;
  label {
    cursor: pointer;
    width: 100%;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
  }
}
input[type="radio"] {
  opacity: 0;
  position: absolute;
}
.button_group {
  min-width: 100%;
  padding: 0 !important;
  padding-top: 5px;
  border-radius: 5px !important;
  margin-bottom: 2px;
}
.dynamic_block_display_container {
  border: 1px solid #e9ecef;
  background: #e9ecef;
  padding: 15px;
  border-radius: 10px;
}
.dynamic_block_display {
  width: 100%;
  max-height: 20vh;
  min-height: 10vh;
  overflow-y: auto;
  position: relative;
  .block_item {
    font-size: 0.9rem;
    width: 90%;
    margin-bottom: 5px;
  }
  .block_item_edit {
    position: absolute;
    visibility: hidden;
    z-index: 999;
    right: 0;
  }
  .block_item_container {
    &:hover {
      .block_item_edit {
        visibility: visible;
      }
    }
  }
}
.patient_record_forms {
  .dynamic_block_display {
    min-height: 150px !important;
  }
}
#diagnoses_table_modal {
  min-height: 200px;
}

.danger-color {
  color: $danger !important;
  &:hover,
  &:active,
  &:focus {
    color: $danger !important;
  }
}
.fontSmall {
  font-size: 0.75rem !important;
}
.required:after {
  content: " *";
  color: rgb(174, 9, 9);
}

.validPassword {
  color: green !important;
}

.invalidPassword {
  color: red !important;
}

.required-white:after {
  content: " *";
  color: rgb(255, 255, 255);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $primary;
  color: $white !important;
  a {
    color: $white !important;
  }
}

.orange1,
.orange2,
.green,
.blue,
.honey {
  background-color: #fff !important;
  .card-body,
  .card-body button {
    color: #525252 !important;
  }
}
.pointerEventsNone {
  pointer-events: none !important;
}
.template_one {
  .category_section {
    // columns: 2;
    // -webkit-columns: 2;
    // -moz-columns: 2;
  }
  .category_title {
    text-align: center;
    background: $primary;
    color: $white;
  }
  .group_title {
    color: $primary;
  }
  .child_title {
    font-style: italic;
  }
}
.template_two {
  .category_box {
    // border:2px solid $primary;
    .group_title {
      background: $primary;
      color: $white;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;
      text-transform: capitalize;
    }
    .create-new-patient-checkboxes {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .child_title {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
  .category_box_profiles {
    // border:2px solid $primary !important;
    th {
      background: $aqua !important;
      color: $white;
      border: none !important;
      text-transform: capitalize;
    }
    td {
      border: none !important;
    }
    .table-responsive {
      min-height: unset !important;
      .table {
        margin-bottom: 0 !important;
      }
    }
  }
}

.modal80h {
  width: 90vw !important;
  max-width: 100% !important;
}
.color-primary {
  color: $primary;
}
.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.printEncounter {
  .text-grey {
    font-size: 0.9rem;
    color: grey;
  }
  .text-primary:first-of-type {
    border-right: 1px solid grey;
  }
  .grey-border-right {
    border-right: 1px solid #c6c6c6;
  }
  .form_block_all {
    color: $primary;
    cursor: pointer;
    i {
      font-weight: 400 !important;
      font-size: 0.75rem;
    }
  }
  .checkbox-wrapper {
    // column-count: 2;
  }
}

.phone-direction {
  display: inline-block;
  direction: ltr !important;
}

.notes-title {
  background: $aqua;
  color: white;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.profiles-th {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.profiles-comments {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.print {
  display: none;
}
.printLogo {
  width: 140px;
  margin-bottom: 20px;
}

.prescriptions-print-page {
  font-size: $print-font-large-size;
  table {
    // border: 1px solid black;
    width: 100%;
    tr {
      // border: 1px solid red;
      width: 100%;
      td {
        // border: 1px solid green;
      }
    }
  }
}

.encounter-print-page {
  font-size: $print-font-size;
}

.encounter-block-title {
  // padding-left: 10px;
  background: $aqua;
  color: white;
  font-size: 1.2rem;
}

.today-container {
  // background: red;
  // display: flex;
  // justify-content: flex-end;
  text-align: end;
}

.main-container {
  padding: 0;
}

// .list {
//   border: 1px solid blue;
// }

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .print-logo {
    width: 230px;
  }
}

.encounter-print-patient-name,
.encounter-print-patient-date,
.encounter-print-patient-id,
.encounter-print-patient-phone {
  text-align: center;
  margin-bottom: -35px;
  font-size: 1.4rem;
  height: 40px;
}

.patient-name-container,
.patient-date-container,
.patient-id-container,
.patient-phone-container {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
}

.prescription-tr {
  font-size: 1.4rem;
}

.text-field-line {
  border-bottom: 1px solid black;
  flex: 1 auto;
}

.lab-request-header,
.lab-request-header-space,
.page-header,
.page-header-space {
  height: 130px;
  // background: greenyellow;
}

.lab-request-header,
.page-header {
  // border: 1px solid black;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  // background: greenyellow;
}

.page-header {
  display: flex;
  justify-content: space-between;
  flex-direction: "row";
  // margin-top: 20px;
  // border-bottom: 1px solid black;
  height: 130px;
  // width:500px;
  // border:2px solid red;
  // width:100%;
  // background: greenyellow;
}

.header-doctor-eng {
  text-align: left;
  font-size: 1.5rem;
  h4 {
    margin-bottom: 30px;
  }
}

.header-doctor-ar {
  text-align: right;
  font-size: 1.5rem;
  h4 {
    margin-bottom: 30px;
  }
}

.lab-request-footer,
.lab-request-footer-space,
.page-footer,
.page-footer-space {
  height: 100px;
}

.lab-request-footer,
.page-footer {
  // border-top: 1px solid black;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.tests-container {
  // justify-content: space-between;
  margin: 0 -15px;
}

.active-tests {
  padding: 15px;
}
.page-footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 1.2rem;
  // background: hotpink;
}

.page-table-cell {
  margin-top: 50px;
  // border: 1px solid black;
}

.page {
  page-break-after: always;
  width: 110% !important;
}

.lab-request-table {
  margin-top: 150px;
  td {
    // border: 1px solid blue;
  }
  width: 100%;
  // border: 1px solid black;
  vertical-align: top !important;
  .lab-request-tbody {
    vertical-align: top !important;
    tr {
      vertical-align: top !important;
      td {
        vertical-align: top !important;
      }
    }
  }
}

@media print {
  * {
    font-size: 1.1rem;
  }

  .patient-name-print-mode-link {
    text-decoration: none !important;
    border-bottom: none !important;
  }

  .patient-name-print-mode-btn {
    color: $blackColor;
  }

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  body {
    margin: 0;
    width: 210mm;
  }

  @page {
    size: A4 !important;
    margin: 10mm !important;
  }
  .print {
    display: block;
    width: 100%;
    height: 100%;
  }
  .modal-backdrop,
  .navigation_conainer {
    display: none !important;
  }
  .modal {
    display: none !important;
  }

  .print-title-text {
    color: $lightGrey !important;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}

.react-tel-input .form-control {
  font-size: 1rem;
  width: inherit;
  height: calc(1.5em + 0.75rem + 2px);
}

.tooltip-inner {
  background-color: $primary;
  color: white;
  min-width: 270px !important;
}

.bs-tooltip-top .arrow::before {
  border-top-color: $primary !important;
}
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $primary !important;
}
.bs-tooltip-right .arrow::before {
  border-right-color: $primary !important;
}
.bs-tooltip-left .arrow::before {
  border-left-color: $primary !important;
}

.instance_select {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page_loader_base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .no-col-padding {
    &.container-fluid {
      display: none;
    }
  }
}
.idFileWrapper {
  width: 200px;
  // height:250px;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.id-label {
  display: flex;
  flex-direction: column;
}

.calendar-container {
  position: relative;

  .calendar-overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
}
.dropdown-item {
  &:hover {
    color: $primary !important;
  }
  &:focus {
    color: $white !important;
  }
  a {
    &:hover {
      color: $primary !important;
    }
    &:focus {
      color: $white !important;
    }
  }
}
.language_change {
  position: absolute;
  right: 20%;
  top: 0;
}
.hide-sm {
  @include respond-below(md) {
    display: none !important;
  }
}

.border_primary {
  border: 2px solid $primary;
  &:before {
    content: "Next Leave - ";
  }
}
.dropdown-item:active {
  .btn {
    color: $white !important;
  }
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: $primary;
  color: $white;
}
.react-datepicker__year-option,
.react-datepicker__month-option {
  &:hover {
    background-color: $white;
    color: $primary;
  }
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: $white !important;
}
.react-datepicker__navigation--next {
  border-left-color: $white !important;
}
.react-datepicker__navigation--previous {
  border-right-color: $white !important;
}

.filterCount {
  position: absolute !important;
  color: #ffffff;
  background: #288dcc;
  border-radius: 50%;
  right: 0%;
}
.encounter-detail-btn {
  // margin-top: 10px;
  margin-left: 5px;
}

.appointment-detail-btn {
  margin-left: 5px;
}

button[variant="primary"],
button[variant="danger"],
button[variant="secondary"] {
  font-weight: bold;
  text-transform: uppercase;
}
.descriptionBox {
  padding: 0.5rem;
  p {
    text-indent: 20px;
  }
}
.border-bottom {
  border-top: 1px solid #dee2e6 !important;
}

.recurring-modal {
  // .modals {
  width: 90vw !important;
  @include respond-below(sm) {
    margin: 0 !important;
    width: 85vw !important;
  }

  // }
}
.removeRef {
  .btn {
    font-size: 12px;
  }
  .red {
    color: $danger;
  }
  display: flex;
  // position: absolute;
  // right:12px;
  // bottom:10px;
}
.intakeformIcon i {
  font-size: 1.3rem;
}

.approveBlockBtn {
  span {
    color: $white;
  }
  padding: 0 5px !important;
}

#stamp {
  // position:relative;
  width: 300px;
  height: 150px;
  object-fit: contain;
  // bottom: 180px;
  // left: 52vw;
  // margin-top: -100px;
}

.card-approved-block {
  cursor: pointer;
}

.arabic {
  text-align: right;
  direction: rtl;
}

.nav-link-with-badge {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.custom-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  background: red;
  border-radius: 50%;
  color: $white;
  font-weight: bold;
  font-size: 0.75rem;
}

.react-tel-input {
  direction: ltr !important;
}

.print.prescriptions-print-page.english {
  direction: ltr !important;
  text-align: left !important;
}

.right-child-buttons {
  // justify-content: flex-start !important;
  padding: 0;
  // @include respond-between(sm, md){}
  @include respond-below(lg) {
    margin-top: 1rem !important;
  }
  @include respond-below(xs) {
    flex-direction: column;
  }
}

.manage-leave-filter-block {
  display: flex !important;
  margin-bottom: 1rem !important;
  justify-content: start !important;
  @include respond-below(md) {
    justify-content: end !important;
  }
}

.right-child-buttons-1 {
  // justify-content: flex-start !important;
  padding: 0;
  // @include respond-between(sm, md){}
  @include respond-below(xs) {
    flex-direction: column;
  }
}

.manual-payment-app-link {
  padding-right: 5px;
  // @include respond-below(xs){
  // }
}

.manual-payment-button-block {
  margin-left: 13rem !important;
}
.cal-link-container {
  margin-top: 10px;
}

.cal-popup-container {
  position: fixed;
  background-color: white;
  /* height: 44px;
  width:150px; */
  z-index: 999;
  font-size: 11.5px;
  border-radius: 3px;
  padding: 2px;
  color: black;
  padding: 10px;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hover-link {
  background-color: white;
  height: 44px;
  width: 50px;
  color: $primary;
  padding: 5px;
  border-radius: 2px;
  margin: 5px;
  border: 1px solid $primary;
}
.hover-link:hover {
  background-color: $primary;
  color: white;
}
.accordion {
  .textGrey {
    &:hover {
      color: grey;
    }
  }
}
.paginationContainer {
  .pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.fc-timegrid-event .fc-event-time {
  white-space: unset !important;
}
.font-size-timestamp {
  font-size: 0.75rem;
}
.text-primary {
  color: $primary !important;
}
.sub_text {
  // font-weight: 700;
  display: block;
  margin-bottom: 0.5rem;
}
.refNumLeft {
  color: $primary;
  font-size: 1.2rem;
  font-weight: 700;
  position: absolute;
  right: 20px;
  top: 20px;
}
.recurringEventSlot {
  background-color: $primary;
  color: $white;
  cursor: default !important;
}
.fc-event-main-frame {
  cursor: pointer;
}
.multiSelectContainer {
  position: relative;
  z-index: 90;
}
.docOptions_2 {
  .dropdown-content {
    z-index: 9999;
    opacity: 1;
    li:nth-child(2) {
      display: none;
    }
  }
}
// .dropdown-content{
//   .search{
//     margin:30px;
//   }
// }

.dropdown-container {
  position: relative;
  z-index: 9;
  background: #f5f6f8 !important;
  &[aria-disabled="true"] {
    background: #f5f6f8 !important;
  }
}
.dropdownOnTop {
  .dropdown-content {
    z-index: 9999;
    opacity: 1 !important;
    top: unset !important;
    bottom: 0% !important; // it will display dropdown content above the input element
  }
}
.whitePreLine {
  white-space: pre-line;
}
.DraftEditor-root {
  height: 500px;
}
.w-md-editor,
.w-md-editor-toolbar,
.wmde-markdown,
.wmde-markdown pre {
  background-color: white;
  color: black;
  svg {
    color: black;
  }
  p {
    margin-bottom: 0 !important;
  }
}
.wmde-markdown {
  max-height: 40vh;
  overflow-y: auto;
}
.w-md-editor-text {
  line-height: unset;
}

// TODO: tabassum please check this. This is for the html editor
// .wrapper-class {
//   border: 1px solid #ccc;
// }
.editor-class {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

  *[contenteditable="false"] {
    background-color: rgb(235, 235, 228);
  }

  .public-DraftEditor-content {
    padding: 0.375rem 0.75rem;
  }
}
.toolbar-class {
  border: 1px solid #ccc;
}

.rdw-editor-toolbar {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.tab-content {
  height: 450px !important;
  overflow-y: auto !important;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.notes-modal p {
  margin-bottom: 0;
}

.notes-tooltip p {
  margin-bottom: 0;
}
.react-datepicker__input-container,
.react-datepicker-time__input {
  input {
    margin: 0;
    font-family: inherit;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
.react-datepicker-time__input {
  input {
    font-size: 0.85rem;
  }
}
.react-datepicker-time__input-container {
  input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
      brightness(95%) contrast(80%);
  }
}
.imageForPatientEditCreate {
  width: 300px;
  max-height: 300px;
  overflow: hidden;
  img {
    object-fit: contain;
  }
}
.spinnerParent {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .spinner-border {
    color: $primary;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon-rotate {
  transition: all 0.3s; /* Add a transition for smooth changes */
  animation: rotate 1s linear infinite; /* Rotate animation over 2 seconds */
}

.icon-circle-red {
  color: red;
}

.icon-circle-grey {
  color: grey;
}

.icon-circle-green {
  color: green;
}

.fc-dotIcon-button,
.fc-reload-button {
  background-color: transparent !important;
  border: none !important;
}

.fc-dotIcon-button {
  pointer-events: none;
}

.fc-kwtButton-button,
.fc-currentTimeButton-button {
  padding: 0 !important;
}

.fc-custom-timezone {
  padding: 0.4em 0.65em;
}

.m-0-5 {
  margin: 0.5rem;
}

.truncate {
  max-width: 220px; /* or any fixed width or max-width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dir-ltr {
  direction: ltr;
}

.patient_nav {
  .nav-link-with-badge {
    &.active {
      background-color: #4169e1;
    }
    &:hover {
      background-color: #4169e1;
    }
  }
}
