.login_form__submit {
  display: flex;
  justify-content: flex-end;
}



.login_form__option_forgot {
  color: white;
  font-style: italic;
  margin-top: 30px;
  text-align: right;
  cursor: pointer;
}
.feedback {
  left: 15px;
}

.form_error {
  margin-top: 15px;
  text-align: center;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}



.validPassword {
  background-color: rgba(0, 255, 0, .5) !important;
  border: 1px solid green !important;
}

.invalidPassword {
  background-color: rgba(250, 0, 0, .1) !important; 
  border: 1px solid red !important;
}

/* Additional styling for the login form */
.login-form input {
  width: 100% !important;
  padding: 10px !important;
  margin-top: 5px !important;
  margin-bottom: 15px !important;
  border-radius: 5px !important;
}